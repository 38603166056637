@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: local('Inter'), url(../public/assets/fonts/Inter/Inter-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: local('Inter'), url(../public/assets/fonts/Inter/Inter-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: local('Inter'), url(../public/assets/fonts/Inter/Inter-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: local('Inter'), url(../public/assets/fonts/Inter/Inter-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: local('Inter'), url(../public/assets/fonts/Inter/Inter-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: local('Inter'), url(../public/assets/fonts/Inter/Inter-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  src: local('Inter'), url(../public/assets/fonts/Inter/Inter-ExtraLight.ttf) format('truetype');
}

.controls {
  display: flex;
  border: 1px solid #ccc;
  border-top: 0;
  padding: 10px;
}

.controls-right {
  margin-left: auto;
}

.state {
  margin: 10px 0;
  font-family: monospace;
}

.state-title {
  color: #999;
  text-transform: uppercase;
}

.quill {
  height: 52px;
  margin-bottom: 40px;
}

.ql-toolbar.ql-snow{
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}

.ql-container.ql-snow{
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
}

*::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}